import React from 'react'

import { PrinterPage } from '../components'

class Printer extends React.Component {
  render() {
    return <PrinterPage />
  }
}

export default Printer
